<template>
    <div>
        <div class="c-actionbar o-contain-padding">
            <nav class="c-breadcrumbs o-nav" aria-label="breadcrumb">
                <router-link
                    v-if="$route.query.company && !editMode"
                    class="back_button"
                    :to="{
                        path: `/company/${$route.query.company}`,
                        query: { activeTab: 'dashboards' },
                    }"
                >
                    <font-awesome-icon class="back_button__icon" :icon="['far', 'chevron-left']" />
                </router-link>
                <ol class="c-breadcrumbs__list o-nav__list">
                    <li
                        v-for="(navItem, index) in navItems"
                        :class="navItemClass(index)"
                        :key="index"
                    >
                        <span v-if="typeof navItem === 'string'">{{ $t(navItem) }}</span>
                        <span
                            v-if="typeof navItem === 'object' && navItem.type === 'label'"
                            :class="navItem.className"
                            >{{ $t(navItem.value) }}</span
                        >
                        <el-input
                            v-if="typeof navItem === 'object' && navItem.type === 'input'"
                            v-model="navItem.value"
                            :class="navItem.className"
                        />
                    </li>
                </ol>
            </nav>
            <div class="c-actionbar__actions">
                <template v-for="(action, i) in actions">
                    <div :key="i">
                        <el-button
                            v-if="action.type === 'button'"
                            v-show="!action.hide"
                            :type="action.btnType"
                            :plain="action.plain"
                            :icon="action.icon"
                            @click="action.handler"
                            :disabled="action.disabled"
                            size="medium"
                            :class="[
                                'c-btn',
                                'c-btn--sm',
                                'c-btn--outline',
                                action.cssClass ? action.cssClass : '',
                            ]"
                        >
                            {{ $t(action.label) }}
                        </el-button>

                        <el-upload
                            v-if="action.type === 'upload'"
                            class="upload-file"
                            :ref="action.ref"
                            :multiple="false"
                            :with-credentials="true"
                            :headers="uploadHeaders"
                            :accept="action.accept"
                            :show-file-list="action.showFileList"
                            :before-upload="action.beforeUpload"
                            :on-success="action.successHandler"
                            :on-error="action.errorHandler"
                            :action="action.uploadUrl"
                        >
                            <el-button
                                type="primary"
                                size="medium"
                                class="c-btn c-btn--sm c-btn--outline"
                                >{{ $t(action.label) }}</el-button
                            >
                        </el-upload>

                        <el-tooltip
                            :openDelay="500"
                            :content="$t(action.tooltip)"
                            :disabled="!action.tooltip"
                            effect="dark"
                            placement="bottom"
                        >
                            <button
                                v-if="action.type === 'drawer-toggle'"
                                :class="[
                                    'c-btn',
                                    'c-btn--clean',
                                    'c-btn--gray',
                                    action.className,
                                    action.isActive ? 'is-active' : '',
                                ]"
                                @click="action.handler"
                            >
                                <i :class="['material-icons', action.className]">{{
                                    action.icon
                                }}</i>
                            </button>
                        </el-tooltip>

                        <el-tooltip
                            :openDelay="500"
                            :content="$t(action.tooltip)"
                            :disabled="!action.tooltip"
                            effect="dark"
                            placement="bottom"
                        >
                            <button
                                v-if="action.type === 'icon-button'"
                                class="c-btn c-btn--clean c-btn--gray"
                                :ref="action.ref"
                                @click="action.handler"
                            >
                                <i class="material-icons">{{ action.icon }}</i>
                            </button>
                        </el-tooltip>

                        <el-tooltip
                            :openDelay="500"
                            :content="$t(action.tooltip)"
                            :disabled="!action.tooltip"
                            effect="dark"
                            placement="bottom"
                        >
                            <div class="c-dropdown" v-if="action.type === 'dropdown'">
                                <button
                                    class="c-btn c-btn--clean c-btn--gray"
                                    :ref="action.ref"
                                    @click="toggleDropdown"
                                    v-click-outside="closeDropdown"
                                >
                                    <i class="material-icons">{{ action.icon }}</i>
                                </button>
                                <div
                                    :class="[
                                        'c-dropdown__container',
                                        'c-dropdown__container--right',
                                        'c-dropdown__container--arrow',
                                        action.cssClass ? action.cssClass : '',
                                    ]"
                                >
                                    <span class="c-dropdown__arrow"
                                        ><span></span><span></span
                                    ></span>
                                    <ul class="c-dropdown__list o-list-plain">
                                        <template v-for="(item, key) in action.items">
                                            <li :key="key">
                                                <button
                                                    v-if="!item.icon"
                                                    class="c-btn c-btn--block"
                                                    @click="item.handler"
                                                >
                                                    {{ $t(item.label) }}
                                                </button>
                                                <button
                                                    v-if="item.icon"
                                                    class="c-btn c-btn--block c-btn--icon"
                                                    @click="item.handler"
                                                >
                                                    <i class="material-icons">{{ item.icon }}</i
                                                    >{{ $t(item.label) }}
                                                </button>
                                            </li>
                                        </template>
                                    </ul>
                                </div>
                            </div>
                        </el-tooltip>
                    </div>
                </template>
            </div>
        </div>
        <main class="o-main o-contain o-contain-padding" role="main">
            <slot></slot>
        </main>
        <confirm-dialog
            :visible="confirmDialogVisible"
            @close="closeConfirmDialog"
            @confirmed="dashboardViewDeletionConfirmed"
            title="DASHBOARD_APP_MODAL_DELETE_DASHBOARD_TITLE"
            message="DASHBOARD_APP_MODAL_DELETE_DASHBOARD_MESSAGE"
            :messageParams="{
                itemTitle: dashboardViewToDelete != null ? dashboardViewToDelete.title : '',
            }"
            inputMessage="MODAL_DELETE_CONFIRM_MSG"
            cancelBtn="CANCEL"
            confirmBtn="DELETE"
            validationText="MODAL_DELETE_CONFIRM"
        />
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { find } from "lodash";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import enElementUiLocale from "element-ui/lib/locale/lang/en";
import nlElementUiLocale from "element-ui/lib/locale/lang/nl";
import { dashboardApiClient } from "@/helpers/evaluation/apiclients";
import { filterTemplateService, splitByService } from "@/services/Evaluation";
import DashboardViewAdd from "@/components/Evaluation/Dashboards/DashboardViewAdd.vue";
import ConfirmDialog from "@/components/Evaluation/confirm-dialog/src/ConfirmDialog.vue";
import util from "@/helpers/evaluation/util";

library.add(faChevronLeft);

export default {
    name: "DashboardsModule",
    mixins: [util, validationMixin],
    components: {
        ConfirmDialog,
        // eslint-disable-next-line
        DashboardViewAdd,
        FontAwesomeIcon,
    },
    props: {
        mode: {
            type: String,
            default: "My Dashboards",
            validate(value) {
                return ["My Dashboards", "Communications", "Results", "WidgetEditor"].includes(
                    value
                );
            },
        },
    },
    data() {
        return {
            loading: false,
            encryptedWidgetId: null,

            myDashboardsLoading: false,
            myDashboardsLoaded: false,

            searchText: "",
            filteredMyDashboardViews: [],

            dashboardViewAddVisible: false,

            dashboardViewToDelete: null,
            confirmDialogVisible: false,

            allApplications: [
                {
                    name: "invites",
                    translationKey: "INVITING_APP",
                    link: "portal/invites",
                    icon: "mail",
                    permissions: ["TITAN_SHOW_INVITING_APP", "API_CREATE_INVITATIONS"],
                    isNew: false,
                },
                {
                    name: "integrations",
                    translationKey: "INTEGRATION_APP",
                    link: "portal/integrations",
                    icon: "device_hub",
                    permissions: ["TITAN_SHOW_INTEGRATION_APP"],
                    isNew: false,
                },
                {
                    name: "reports",
                    translationKey: "REPORT_GENERATOR",
                    link: "portal/reports",
                    icon: "cloud_download",
                    permissions: ["TITAN_SHOW_REPORT_GENERATOR"],
                    anyPermissions: ["DOWNLOAD_PDF", "DOWNLOAD_EXCEL"],
                    isNew: false,
                },
                {
                    name: "evaluation",
                    translationKey: "EVALUATION",
                    link: "cgi-bin/react_tool_repinterface.pl?Op=RepPageStatic&p=platform/tab_profiel",
                    icon: "share",
                    permissions: ["TITAN_SHOW_EVALUATION"],
                    isNew: false,
                },
                {
                    name: "help",
                    translationKey: "HELP",
                    link: "#community-help",
                    icon: "contact_support",
                    isNew: false,
                },
            ],
            helpUrl: "https://docs.tevreden.nl/nl/handleiding-portal/dashboard",
            uploadHeaders: {
                "X-XSRF-TOKEN": "",
            },

            pageTitleKey: "TEVREDEN_DASHBOARD",

            styleSheet: null,
        };
    },
    computed: {
        role() {
            return "ROLE_ADMIN";
            // return this.$store.state.sharedStore.userData.role;
        },
        permissions() {
            return ["ROLE_ADMIN"];
            // return this.$store.state.sharedStore.userData.permissions;
        },
        navItems() {
            return this.$store.state.DashboardStore.navItems;
        },
        actions() {
            return this.$store.state.DashboardStore.actionBarItems;
        },
        dashboards() {
            return this.$store.state.DashboardStore.dashboards;
        },
        myDashboardViews() {
            return this.$store.state.DashboardStore.myDashboardViews;
        },
        activeDashboard() {
            return this.$store.state.DashboardStore.activeDashboard;
        },
        activeDashboardView() {
            return this.$store.state.DashboardStore.activeDashboardView;
        },
        editMode() {
            return this.$store.state.DashboardStore.dashboardEditMode;
        },
        myDashboard() {
            let myDashboard = null;
            $.each(this.dashboards, (index, dashboard) => {
                if (dashboard.name === "My Dashboards") {
                    myDashboard = dashboard;
                    return false;
                }
            });
            return myDashboard;
        },
        userSettingsDialogVisible() {
            // return this.$store.state.sharedStore.userSettingsDialogVisible;
            return false;
        },
    },
    methods: {
        navItemClass(index) {
            let cls = "c-breadcrumbs__item o-nav__item";
            if (this.navItems.length === index + 1) {
                cls += " is-active";
            }
            return cls;
        },
        closeUserSettings() {
            this.$store.commit("DashboardStore/setUserSettingsDialogVisible", false);
        },
        getDashboardName(dashboard) {
            if (dashboard.name === "Results") {
                return this.translate("RESULTS_DASHBOARD");
            }
            if (dashboard.name === "Communications") {
                return this.translate("COMMUNICATIONS_DASHBOARD");
            }
            if (dashboard.name === "My Dashboards") {
                return this.translate("MY_DASHBOARDS");
            }
            return this.translate(dashboard.name);
        },
        getDashboards() {
            this.loading = true;

            dashboardApiClient
                .get("/dashboards")
                .then((response) => {
                    const dashboards = [];
                    if (response.data.length > 0) {
                        $.each(response.data, (index, dashboard) => {
                            // @fixme 'editable' prop been bypassed due an
                            //  internal agreement (ticket SP-497). In the
                            //  future this prop should be controlled at BE
                            //  and this modification get rid.
                            dashboards.push({
                                id: dashboard.id,
                                name: dashboard.name,
                                editable: true,
                                position: dashboard.dashboardPosition,
                                label: dashboard.labelName,
                                communicationsDashboard: dashboard.communicationsDashboard,
                            });
                        });

                        dashboards.sort(this.objectSortFunc("position"));

                        this.$store.commit("DashboardStore/setDashboards", dashboards);

                        this.setActiveDashboard(true);
                    }
                })
                .catch(() => {
                    this.loading = false;
                    this.displayMessage(
                        this.translate("DASHBOARD_APP_ERROR_GETTING_DASHBOARDS"),
                        "error"
                    );
                });
        },
        setActiveDashboard(reloadMyDashboards) {
            // eslint-disable-next-line prefer-destructuring
            const activeDashboard = find(this.dashboards, { name: this.mode });

            this.selectDashboard(activeDashboard);
            this.getDashboardViews(activeDashboard, true, reloadMyDashboards);
        },
        getDashboardViews(dashboard, selectView, reloadMyDashboards) {
            if (
                dashboard.name === "My Dashboards" &&
                this.myDashboardsLoaded &&
                !reloadMyDashboards
            ) {
                if (selectView) {
                    this.selectDashboardView(this.myDashboardViews);
                }
            } else {
                this.loading = true;
                if (dashboard.name === "My Dashboards") {
                    this.myDashboardsLoading = true;
                }
                dashboardApiClient
                    .get(`/dashboards/${dashboard.id}/dashboard-views`)
                    .then((response) => {
                        this.loading = false;
                        const dashboardViews = [];
                        if (response.data.length > 0) {
                            $.each(response.data, (index, dashboardView) => {
                                try {
                                    const configuration = JSON.parse(dashboardView.configuration);
                                    if (dashboardView.labelName != null) {
                                        configuration.label = dashboardView.labelName;
                                    }
                                    dashboardViews.push({
                                        id: dashboardView.id,
                                        title: configuration.title,
                                        configuration,
                                        position: dashboardView.dashboardViewPosition,
                                        dashboard: dashboardView.dashboard.id,
                                        editable: dashboard.editable,
                                        template: dashboardView.dashboardViewTemplate,
                                    });
                                } catch (error) {
                                    /* eslint-disable no-console */
                                    console.log(error);
                                }
                            });

                            dashboardViews.sort(this.objectSortFunc("position"));

                            if (dashboard.name === "My Dashboards") {
                                this.$store.commit(
                                    "DashboardStore/setMyDashboardViews",
                                    dashboardViews
                                );
                                this.myDashboardsLoaded = true;
                                this.myDashboardsLoading = false;
                            }

                            if (selectView) {
                                this.selectDashboardView(dashboardViews);
                            }
                        }
                    })
                    .catch((error) => {
                        /* eslint-disable no-console */
                        console.log(error);
                        this.loading = false;
                        this.displayMessage(
                            this.translate("DASHBOARD_APP_ERROR_GETTING_DASHBOARD_VIEWS"),
                            "error"
                        );
                    });
            }
        },
        selectDashboard(dashboard) {
            this.$store.commit("DashboardStore/setActiveDashboard", dashboard);
            this.$storage.setItem("dashboard", dashboard);
        },
        selectDashboardView(dashboardViews) {
            if (!dashboardViews.length) return;

            // select view
            let selectedView = null;

            if (selectedView == null) {
                const navigatedDashboard = find(dashboardViews, {
                    id: Number(this.$route.params.id),
                });

                if (navigatedDashboard) {
                    selectedView = navigatedDashboard;
                } else {
                    // eslint-disable-next-line prefer-destructuring
                    selectedView = dashboardViews[0];
                }
            }

            this.setActiveDashboardView(selectedView);
        },
        /**
         * @see comment above selectDashboardView method.
         * @param view
         */
        setActiveDashboardView(view) {
            if (this.activeDashboardView == null || this.activeDashboardView.id !== view.id) {
                this.$store.commit("DashboardStore/setActiveDashboardView", view);
                this.$store.commit("DashboardStore/setNavItems", [
                    {
                        type: "label",
                        value: view.configuration.title,
                        className: "dashboard-title-label",
                    },
                ]);
                const storageView = this.$storage.getItem("selectedView");
                if (view !== storageView) {
                    if (view) {
                        this.$storage.setItem("selectedView", view);
                    } else {
                        this.$storage.removeItem("selectedView");
                    }
                }
            }
        },
        setFilteredMyDashboardViews() {
            this.filteredMyDashboardViews = this.myDashboardViews.filter(this.checkSearchCriteria);
        },
        checkSearchCriteria(dashboardView) {
            // check search text
            if (this.searchText == null || this.searchText.trim() === "") {
                return true;
            }
            return dashboardView.title.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1;
        },
        addNewDashboardView() {
            this.dashboardViewAddVisible = true;
        },
        closeDashboardViewAdd() {
            this.dashboardViewAddVisible = false;
        },
        dashboardViewAdded(view) {
            this.selectDashboard(this.myDashboard);
            this.setActiveDashboardView(view);
        },
        deleteDashboardView(dashboardView) {
            this.dashboardViewToDelete = dashboardView;
            this.openConfirmDialog();
        },
        openConfirmDialog() {
            this.confirmDialogVisible = true;
        },
        closeConfirmDialog() {
            this.confirmDialogVisible = false;
        },
        dashboardViewDeletionConfirmed() {
            this.closeConfirmDialog();

            if (this.dashboardViewToDelete == null) {
                return;
            }

            dashboardApiClient
                .delete(
                    `/dashboards/${this.dashboardViewToDelete.dashboard}/dashboard-views/${this.dashboardViewToDelete.id}`
                )
                .then(() => {
                    this.$store.commit(
                        "DashboardStore/removeDashboardView",
                        this.dashboardViewToDelete
                    );
                    this.closeConfirmDialog();
                    this.displayMessage(
                        this.translate("DASHBOARD_APP_DASHBOARD_VIEW_DELETED"),
                        "success"
                    );
                    if (
                        this.activeDashboard != null &&
                        this.activeDashboard.name === "My Dashboards"
                    ) {
                        if (this.myDashboardViews.length > 0) {
                            this.setActiveDashboardView(this.myDashboardViews[0]);
                        } else {
                            this.selectDashboard(this.dashboards[0]);
                            this.getDashboardViews(this.dashboards[0], true, false);
                        }
                    }
                })
                .catch(() => {
                    this.displayMessage(
                        this.translate("DASHBOARD_APP_ERROR_DELETING_DASHBOARD_VIEW"),
                        "error"
                    );
                });
        },
        moveDashboard(data) {
            if (data.fromIndex !== data.toIndex && data.fromIndex < this.dashboards.length) {
                const dashboard = this.dashboards[data.fromIndex];
                if (dashboard != null) {
                    const newPosition = this.getNewPosition(
                        dashboard.position,
                        data.toIndex - data.fromIndex,
                        this.dashboards.length - 1
                    );
                    if (dashboard.position !== newPosition) {
                        dashboardApiClient
                            .post(`/dashboards/${dashboard.id}/reorder/${newPosition}`, "", {
                                headers: {
                                    "Content-Type": "application/json",
                                },
                            })
                            .then(() => {
                                this.$store.commit("DashboardStore/moveDashboard", {
                                    id: dashboard.id,
                                    oldPosition: dashboard.position,
                                    newPosition,
                                });
                            })
                            .catch(() => {
                                this.displayMessage(
                                    this.translate("DASHBOARD_APP_ERROR_REORDERING_DASHBOARDS"),
                                    "error"
                                );
                            });
                    }
                }
            }
        },
        moveDashboardView(fromIndex, toIndex) {
            if (fromIndex !== toIndex && fromIndex < this.myDashboardViews.length) {
                const dashboardView = this.myDashboardViews[fromIndex];
                if (dashboardView != null && this.myDashboard != null) {
                    const newPosition = this.getNewPosition(
                        dashboardView.position,
                        toIndex - fromIndex,
                        this.myDashboardViews.length - 1
                    );
                    if (dashboardView.position !== newPosition) {
                        dashboardApiClient
                            .post(
                                `/dashboards/${this.myDashboard.id}/dashboard-views/${dashboardView.id}/reorder/${newPosition}`,
                                "",
                                {
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                }
                            )
                            .then(() => {
                                this.$store.commit("DashboardStore/moveMyDashboardView", {
                                    id: dashboardView.id,
                                    oldPosition: dashboardView.position,
                                    newPosition,
                                });
                            })
                            .catch(() => {
                                this.displayMessage(
                                    this.translate("DASHBOARD_APP_ERROR_REORDERING_DASHBOARDS"),
                                    "error"
                                );
                            });
                    }
                }
            }
        },
        getNewPosition(oldPosition, diff, maxPosition) {
            let newPosition = (oldPosition || 0) + diff;
            if (newPosition < 0) {
                newPosition = 0;
            } else if (newPosition > maxPosition) {
                newPosition = maxPosition;
            }
            return newPosition;
        },
        isDashboardActive(dashboard) {
            return (
                this.isScheduleReportsRoute() &&
                this.activeDashboard != null &&
                this.activeDashboard.id === dashboard.id
            );
        },
        isDashboardViewActive(dashboardView) {
            return (
                this.isScheduleReportsRoute() &&
                this.activeDashboardView != null &&
                this.activeDashboardView.id === dashboardView.id
            );
        },
        isScheduleReportsRoute() {
            return (
                this.$router.currentRoute != null &&
                this.$router.currentRoute.name === "schedule-reports"
            );
        },
        canAccessDashboard() {
            return (
                this.role === "ROLE_ADMIN" ||
                this.role === "ROLE_SUPERADMIN" ||
                this.permissions.indexOf("TITAN_SHOW_DASHBOARD") > -1
            );
        },
    },
    watch: {
        myDashboardViews() {
            this.setFilteredMyDashboardViews();
        },
    },
    beforeCreate() {
        this.$i18n.mergeLocaleMessage("en", enElementUiLocale);
        this.$i18n.mergeLocaleMessage("nl", nlElementUiLocale);
    },
    created() {
        if (this.mode === "WidgetEditor") return;
        this.getDashboards();
        splitByService.initProperties();
        filterTemplateService.getAll();
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/evaluation/dashboards-common.css";
@import "~element-ui/lib/theme-chalk/index.css";
@import "../../assets/css/evaluation/custom.css";
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_colors";

.el-button--primary {
    background-color: $blue;
    border-color: $blue;
    text-transform: capitalize;
    font-weight: 600;
}

.c-actionbar {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    position: sticky;
    top: 0;
    z-index: 3;
    min-width: 0;
    &.o-contain-padding {
        padding-right: 120px;
    }
}
.c-breadcrumbs {
    &.o-nav {
        display: flex;
        align-items: center;

        .c-breadcrumbs__list {
            &.o-nav__list {
                display: flex;
                align-items: center;
            }
        }
    }
}

.o-main {
    padding-top: 60px;
}

.back_button {
    color: $blue;
    font-size: 1.25rem;
    text-decoration: none;
    font-weight: 600;
    margin-right: 12px;
}
</style>
